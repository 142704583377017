const infoText = {
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: '600',
  textDecoration: 'underline',
};

export const feedbackStyles = () => ({
  container: {
    '& label': {
      margin: '5px 0px',
    },
    fontSize: '12px',
  },
  screenshotCheckBox: {
    margin: '6px 0px 16px',
  },
  screenshotContainer: {
    height: '220px',
    border: '1px solid #979593',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  remainingChar: {
    textAlign: 'right',
  },
  emailCheckBox: {
    marginTop: '16px',
  },
  privacyStatement: {
    marginTop: '12px',
    fontSize: '12px',
    lineHeight: '20px',
  },
  userVoiceStatement: {
    marginTop: '12px',
    fontSize: '12px',
    lineHeight: '20px',
  },
  submitButton: {
    marginRight: '12px',
  },
  infoText,
});

export const choiceContentStyles = () => ({
  ChoiceBoxHeader: {
    fontSize: '12px',
    lineHeight: '20px',
    color: '#323130',
    marginBottom: '19px',
  },
  choiceBoxContainer: {
    height: '230px',
  },
  choiceBoxWrapper: {
    padding: '2px',
  },
  choiceBoxBase: {
    height: '64px',
    boxShadow: '0px 1.6px 3.6px rgba(0, 0, 0, 0.132), 0px 0.3px 0.9px rgba(0, 0, 0, 0.108)',
    borderRadius: '2px',
    outlineColor: '#0078D4',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  choiceBox: {
    border: '1px solid transparent',
    paddingLeft: '15px',
    '&:hover': {
      border: '1px solid #0078D4',
    },
  },
  choiceBoxSelected: {
    border: '2px solid #0078D4',
    paddingLeft: '14px',
  },
  choiceDetail: {
    lineHeight: '18px',
    fontSize: '12px',
  },
  choiceHeader: {
    lineHeight: '20px',
  },
  choiceIcon: {
    width: '20px',
    height: '20px',
    color: 'rgba(50, 49, 48, 1)',
  },
  infoText,
});
