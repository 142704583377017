import $ from 'jquery';

export class OCVDataService {
  checkCompliance() {
    throw new Error('Do not use this service with a new UI or survey questions without completing OCV onboarding review');
  }

  sendFeedbackToOCV({
    formData,
    isTestEnv,
    onSuccess,
    onError,
  }) {
    const feedbackUrl = isTestEnv ?
      'https://petrol-int.office.microsoft.com/v1/feedback' : 'https://petrol.office.microsoft.com/v1/feedback';

    $.ajax({
      url: feedbackUrl,
      type: 'POST',
      data: formData,
      contentType: false,
      processData: false,
      success: onSuccess,
      error: onError,
    });
  }

  sendFeedback({
    feedback,
    screenshotGenerationPromise,
    onSuccess,
    onError,
    appId,
    clientFeedbackId,
    isTestEnv,
    telemetry,
    manifestType = 'Sas',
  }) {
    this.checkCompliance();

    const content = {
      appId,
      clientFeedbackId,
      manifestType,
      source: 'Client',
      submitTime: new Date(),
      telemetry,
      ...feedback,
    };

    const manifestData = new Blob([JSON.stringify(content)], { type: 'application/json' });
    const formData = new FormData();

    formData.append('Manifest', manifestData);

    if (screenshotGenerationPromise) {
      screenshotGenerationPromise.then((screenshotData) => {
        const imgString = screenshotData.split(',')[1];
        const blobBin = atob(imgString);
        const array = [];

        for (let i = 0; i < blobBin.length; i += 1) {
          array.push(blobBin.charCodeAt(i));
        }

        formData.append('Screenshot', new Blob([new Uint8Array(array)], { type: 'image/jpeg' }));
      }).finally(() => {
        this.sendFeedbackToOCV({
          formData,
          isTestEnv,
          onSuccess,
          onError,
        });
      });
    } else {
      this.sendFeedbackToOCV({
        formData,
        isTestEnv,
        onSuccess,
        onError,
      });
    }
  }
}
