/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ReactTemplate } from '@bingads-webui-react/react-template';
import { Glyphicon } from '@bingads-webui-react/primitive';
import classNames from 'classnames';
import { Link } from '@bingads-webui-react/fabric-control';
import { withDefaultStyles } from '@bingads-webui-react/with-default-styles';
import { keyCode } from '@bingads-webui/keycode';

import { choiceContentStyles } from './feedback-styles';

export const feedbackOptions = {
  smile: 'Smile',
  frown: 'Frown',
  idea: 'Idea',
};

const Option = (props) => {
  const {
    text,
    detail,
    glyph,
    onClick,
    onKeyDown,
    selected,
    classes,
  } = props;

  return (
    <div
      className={classNames(classes.choiceBoxWrapper, 'choice')}
      role="option"
      tabIndex="0"
      aria-selected={selected}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <div
        className={classNames(
          classes.choiceBoxBase,
          selected ? classes.choiceBoxSelected : classes.choiceBox
      )}
      >
        <div className={classes.choiceHeader}>
          <Glyphicon
            className={classes.choiceIcon}
            glyph={glyph}
          />
          {text}
        </div>
        <div className={classes.choiceDetail}>
          {detail}
        </div>
      </div>
    </div>
  );
};

Option.propTypes = {
  text: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  glyph: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  classes: PropTypes.shape().isRequired,
};

const ChoiceContent = (props) => {
  const {
    selectedKey,
    i18n,
    triggerHelpPanel,
    classes,
    setSmile,
    onKeyDownSmile,
    setFrown,
    onKeyDownFrown,
    setIdea,
    onKeydownIdea,
    openLinkService,
  } = props;

  const renderLinkContent = useCallback((text, classname, url) => {
    if (openLinkService && openLinkService.openLinkInBrowser) {
      return (
        <Link
          onClick={() => { openLinkService.openLinkInBrowser(url); }}
          onKeyUp={(event) => {
            if (event.keyCode === keyCode.Enter || event.keyCode === keyCode.Space) {
              openLinkService.openLinkInBrowser(url);
            }
          }}
          className={classNames(classes.infoText, classname)}
          aria-label={text}
          as="a" // Render as <a /> to meet A11Y requirement
          tabIndex="0"
        >
          {text}
        </Link>
      );
    }
    return (
      <Link
        onClick={triggerHelpPanel}
        className={classNames(classes.infoText, classname)}
      >
        {text}
      </Link>
    );
  }, [classes.infoText, openLinkService, triggerHelpPanel]);

  return (
    <React.Fragment>
      <div className={classes.ChoiceBoxHeader}>
        <ReactTemplate
          model={{
              l1: text => (
                renderLinkContent(text, 'contact-support', 'https://go.microsoft.com/fwlink?LinkID=398371')
              ),
              l2: text => (
                renderLinkContent(text, 'help-center', 'https://help.ads.microsoft.com/')
              ),
            }}
          template={
              i18n.getString(_TL_("We'd love to hear what's working for you or how we can do better. However, if you have questions please  {{l1}}contact support{{/l1}} or visit our {{l2}}help center{{/l2}}."))
            }
        />
      </div>
      <div className={classes.choiceBoxContainer} role="listbox" aria-label={i18n.getString(_TL_('feedback options'))}>
        <Option
          key={feedbackOptions.smile}
          selected={feedbackOptions.smile === selectedKey}
          text={i18n.getString(_TL_('I like something'))}
          detail={i18n.getString(_TL_('Tell us about it.'))}
          glyph="ba-Emoji2"
          onClick={setSmile}
          onKeyDown={onKeyDownSmile}
          classes={classes}
        />
        <Option
          key={feedbackOptions.frown}
          selected={feedbackOptions.frown === selectedKey}
          text={i18n.getString(_TL_('I don\'t like something'))}
          detail={i18n.getString(_TL_('Let us know how we can do better.'))}
          glyph="ba-Sad"
          onClick={setFrown}
          onKeyDown={onKeyDownFrown}
          classes={classes}
        />
        <Option
          key={feedbackOptions.idea}
          selected={feedbackOptions.idea === selectedKey}
          text={i18n.getString(_TL_('I have a suggestion'))}
          detail={i18n.getString(_TL_('Share an idea or improvement.'))}
          glyph="ba-FeatureRequest"
          onClick={setIdea}
          onKeyDown={onKeydownIdea}
          classes={classes}
        />
      </div>
    </React.Fragment>
  );
};

ChoiceContent.propTypes = {
  selectedKey: PropTypes.string.isRequired,
  i18n: PropTypes.shape().isRequired,
  triggerHelpPanel: PropTypes.func.isRequired,
  setSmile: PropTypes.func.isRequired,
  onKeyDownSmile: PropTypes.func.isRequired,
  setFrown: PropTypes.func.isRequired,
  onKeyDownFrown: PropTypes.func.isRequired,
  setIdea: PropTypes.func.isRequired,
  onKeydownIdea: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired,
  openLinkService: PropTypes.shape({ openLinkInBrowser: PropTypes.func }),
};

ChoiceContent.defaultProps = {
  openLinkService: {},
};

const Wrapped = withDefaultStyles(ChoiceContent, choiceContentStyles);

export { Wrapped as ChoiceContent };
