import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { getDisplayName } from '@bingads-webui-react/hoc-utils';

export const withCustomClassName = (WrappedComponent, customClassName, bsRole = '') => {
  const WrappedComponentWithCustomClassName = (props) => {
    const {
      bsRole: role,
      className,
      forwardedRef,
      ...rest
    } = props;

    const nameProp = classNames(className, customClassName);

    return (<WrappedComponent className={nameProp} ref={forwardedRef} {...rest} />);
  };

  WrappedComponentWithCustomClassName.propTypes = {
    className: PropTypes.string,
    forwardedRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    bsRole: PropTypes.string.isRequired,
  };

  WrappedComponentWithCustomClassName.defaultProps = {
    className: '',
    forwardedRef: null,
  };

  WrappedComponentWithCustomClassName.displayName = `withCustomClassName(${getDisplayName(WrappedComponent)})`;

  hoistNonReactStatics(WrappedComponentWithCustomClassName, WrappedComponent);

  const WrappedComponentWithForwardRef = React.forwardRef((props, ref) =>
    <WrappedComponentWithCustomClassName forwardedRef={ref} {...props} />);

  WrappedComponentWithForwardRef.propTypes = {
    bsRole: PropTypes.string,
  };

  WrappedComponentWithForwardRef.defaultProps = {
    bsRole,
  };

  return WrappedComponentWithForwardRef;
};
