/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid';
import classNames from 'classnames';
import html2canvas from 'html2canvas';
import { extend, isEmpty, delay } from 'underscore';
import {
  Label,
  Panel,
  PanelType,
  DefaultButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField,
  Text,
  Checkbox,
  Dialog,
  DialogType,
  Link,
  DialogFooter
} from '@bingads-webui-react/fabric-control';
import { ReactTemplate } from '@bingads-webui-react/react-template';
import { withDefaultStyles } from '@bingads-webui-react/with-default-styles';
import { curlyTemplate } from '@bingads-webui-universal/primitive-utilities';
import { keyCode } from '@bingads-webui/keycode';
import { setClarityTag } from '@bingads-webui-universal/clarity';
import { Glyphicon } from '@bingads-webui-react/primitive';
import { ComponentSettings } from '@bingads-webui-theme-2018/theme-fluent';
import { feedbackStyles } from './feedback-styles';
import { ChoiceContent, feedbackOptions } from './choice-content';
import { OCVDataService } from './ocv-data-service';

const remainingCharacterTemplateString = _TL_('{{count}} characters remaining');

const userVoiceString = _TL_('Please visit the feedback forum to share and vote on features that you’d like added to Microsoft Advertising. {{a}}Microsoft Advertising Feature Suggestions - Community{{/a}}');

const feedbackMaxLength = 2000;

let feedbackMessageSaved = '';
let feedbackOptionSaved = '';

class SasOCVDataSource extends OCVDataService {
  checkCompliance() {
    return true;
  }
}

export function FeedbackUnthemed(props) {
  const {
    i18n,
    classes,
    instrumentationService,
    appId,
    dismissPanel,
    isOpen,
    isTestEnv,
    email,
    sessionId,
    pageId,
    takeScreenShot,
    featureArea,
    openLinkService,
    appData,
  } = props;

  const [selectedKey, setSelectedKey] = useState(feedbackOptionSaved || feedbackOptions.smile);
  const [feedbackMsg, setFeedbackMsg] = useState(feedbackMessageSaved);
  const [emailSelection, setEmailSelection] = useState(false);
  const [emailAddress, setEmailAddress] = useState(email);
  const [selectScreenshot, setSelectScreenshot] = useState(false);
  const [screenshot, setScreenshot] = useState(null);
  const [screenshotError, setScreenshotError] = useState(false);
  const [blockSubmit, setBlockSubmit] = useState(false);
  const [clearSavedInfo, setClearSavedInfo] = useState(true);
  const [hideDialog, setHideDialog] = useState(true);
  const globalHelpRef = useRef(null);
  const dataService = new SasOCVDataSource();

  const hidePanel = useCallback(() => {
    if (clearSavedInfo) {
      feedbackMessageSaved = '';
      feedbackOptionSaved = '';
      setFeedbackMsg('');
      setSelectedKey(feedbackOptions.smile);
    } else {
      setClearSavedInfo(true);
    }

    instrumentationService.log(
      JSON.stringify({
        message: 'FeedbackPanel: feedback panel dismissed.',
      }),
      'Feedback'
    );
    dismissPanel();
  }, [clearSavedInfo, dismissPanel, instrumentationService]);

  const saveAndDismiss = useCallback(() => {
    feedbackMessageSaved = feedbackMsg;
    feedbackOptionSaved = selectedKey;
    setClearSavedInfo(false);
    dismissPanel();
  }, [dismissPanel, feedbackMsg, selectedKey]);

  const triggerHelpPanel = useCallback(() => {
    saveAndDismiss();
    instrumentationService.log(
      JSON.stringify({
        message: 'FeedbackPanel: clicked help center',
      }),
      'Feedback'
    );
    globalHelpRef.current.click();
  }, [instrumentationService, saveAndDismiss]);

  const setSmile = useCallback(() => {
    setSelectedKey(feedbackOptions.smile);
  }, []);
  const setFrown = useCallback(() => {
    setSelectedKey(feedbackOptions.frown);
  }, []);
  const setIdea = useCallback(() => {
    setSelectedKey(feedbackOptions.idea);
  }, []);
  const onKeyDownSmile = useCallback((e) => {
    if (e.keyCode === keyCode.Enter || e.keyCode === keyCode.Space) {
      setSmile();
    }
  }, [setSmile]);
  const onKeyDownFrown = useCallback((e) => {
    if (e.keyCode === keyCode.Enter || e.keyCode === keyCode.Space) {
      setFrown();
    }
  }, [setFrown]);
  const onKeydownIdea = useCallback((e) => {
    if (e.keyCode === keyCode.Enter || e.keyCode === keyCode.Space) {
      setIdea();
    }
  }, [setIdea]);
  const handleFeedbackChange = useCallback((_e, newValue) => {
    setFeedbackMsg((newValue || '').substring(0, feedbackMaxLength));
  }, []);
  const handleEmailSelectionChange = useCallback((_e, newValue) => {
    setEmailSelection(newValue);
  }, []);
  const handleEmailChange = useCallback((_e, newValue) => {
    setEmailAddress(newValue);
  }, []);
  const handleScreenshotSelectionChange = useCallback((_e, newValue) => {
    setSelectScreenshot(newValue);
  }, []);
  const screenshotDataPromise = useRef(null);

  useEffect(() => {
    if (!isOpen) {
      screenshotDataPromise.current = null;
      setEmailSelection(false);
      setSelectScreenshot(false);
    } else {
      instrumentationService.log(
        JSON.stringify({
          message: 'FeedbackPanel: feedback panel opened',
        }),
        'Feedback'
      );
      setClarityTag({ FeedbackPanel: 'true' });
    }
  }, [instrumentationService, isOpen]);

  useEffect(() => {
    if (selectScreenshot && !screenshot) {
      setScreenshotError(false);
      // Taking screenshot is expensive. delay for checkbox animation.
      delay(() => {
        window.requestAnimationFrame(() => {
          screenshotDataPromise.current = html2canvas(document.body, {
            foreignObjectRendering: true,
            logging: false,
          }).then((canvas) => {
            const screenshotData = canvas.toDataURL('image/jpeg');
            setScreenshot(screenshotData);
            return screenshotData;
          }).catch((e) => {
            setScreenshotError(true);
            if (e) {
              const message = `${e.message || ''}${e.stack || ''}`;
              instrumentationService.log(
                `FeedbackPanel: Screenshot Error: ${message || JSON.stringify(e)}`,
                'Feedback'
              );
            }
          });
        });
      }, 200);
    }
  }, [instrumentationService, screenshot, selectScreenshot, setScreenshotError]);

  const submitFeedback = useCallback(() => {
    setBlockSubmit(true);
    hidePanel();

    const clientFeedbackId = uuidv4();
    const success = () => {
      instrumentationService.log(
        JSON.stringify({
          message: 'FeedbackPanel: feedback submission successful',
          clientFeedbackId,
        }),
        'Feedback'
      );
    };

    const error = () => {
      hidePanel();
      instrumentationService.log(
        JSON.stringify({
          message: 'FeedbackPanel: feedback submission failed',
          clientFeedbackId,
        }),
        'Feedback'
      );
    };

    const feedback = {
      type: selectedKey,
      comment: feedbackMsg,
    };

    if (emailSelection && emailAddress) {
      extend(feedback, { email: emailAddress });
    }

    if (window && window.location && window.location.href) {
      extend(feedback, { web: { sourcePageURI: window.location.href } });
    }

    if (pageId) {
      extend(appData, { pageId });
    }

    extend(feedback, {
      application: {
        appData: JSON.stringify({ pageId, ...appData }),
      },
    });

    const telemetry = {};

    if (sessionId) {
      extend(telemetry, { processSessionId: sessionId });
    }

    if (featureArea) {
      extend(telemetry, { featureArea });
    }

    dataService.sendFeedback({
      feedback,
      telemetry: isEmpty(telemetry) ? undefined : telemetry,
      screenshotGenerationPromise: selectScreenshot ? screenshotDataPromise.current : undefined,
      onSuccess: success,
      onError: error,
      appId,
      clientFeedbackId,
      isTestEnv,
    });
  }, [
    appId,
    emailAddress,
    emailSelection,
    featureArea,
    feedbackMsg,
    hidePanel,
    instrumentationService,
    isTestEnv,
    pageId,
    selectScreenshot,
    selectedKey,
    sessionId,
    dataService,
    appData,
  ]);

  const onRenderDescription = useCallback(() => (
    <Label className={classes.remainingChar} >
      <Text variant="smallPlus" >
        {curlyTemplate(i18n.getString(remainingCharacterTemplateString))({
          count: feedbackMaxLength - (feedbackMsg ? feedbackMsg.length : 0),
        })}
      </Text>
    </Label>
  ), [classes.remainingChar, feedbackMsg, i18n]);

  const enableSubmit = useCallback(
    () => !blockSubmit && selectedKey !== feedbackOptions.idea &&
      (feedbackMsg),
    [blockSubmit, feedbackMsg, selectedKey]
  );

  const handleClickSubmit = useCallback(() => {
    setHideDialog(false);
  }, []);

  const handleCancel = useCallback(() => {
    setHideDialog(true);
    hidePanel();
    instrumentationService.log(
      JSON.stringify({
        message: 'FeedbackPanel: clicked cancel in dialog.',
      }),
      'Feedback'
    );
  }, [hidePanel, instrumentationService]);

  const handleSend = useCallback(() => {
    submitFeedback();
    setHideDialog(true);
    instrumentationService.log(
      JSON.stringify({
        message: 'FeedbackPanel: clicked submit in dialog.',
      }),
      'Feedback'
    );
  }, [instrumentationService, submitFeedback]);

  const renderFooterContent = useCallback(() => (
    <div className="footer">
      <PrimaryButton
        className={classNames(classes.submitButton, 'submit')}
        text={i18n.getString(_TL_('Submit feedback'))}
        onClick={handleClickSubmit}
        disabled={!enableSubmit()}
      />
      <DefaultButton
        className="cancel"
        text={i18n.getString(_TL_('Cancel'))}
        onClick={hidePanel}
      />
    </div>
  ), [classes.submitButton, enableSubmit, handleClickSubmit, hidePanel, i18n]);

  const renderScreenshotContainer = useCallback(() => {
    if (!selectScreenshot) {
      return null;
    }

    let screenshotContent = null;

    if (screenshotError) {
      screenshotContent = i18n.getString(_TL_('There is an error taking screenshot. Please retry or submit without a screenshot.'));
    } else if (screenshot) {
      screenshotContent = (<img className={classes.img} alt={i18n.getString(_TL_('Screenshot Preview'))} src={screenshot} />);
    } else {
      screenshotContent = (<Spinner size={SpinnerSize.medium} />);
    }

    return (
      <div className={classNames(classes.screenshotContainer, 'screenshot-container')}>
        {screenshotContent}
      </div>
    );
  }, [
    classes.img,
    classes.screenshotContainer,
    i18n,
    screenshot,
    screenshotError,
    selectScreenshot,
  ]);

  const onOuterClick = useCallback(() => {
    saveAndDismiss();
  }, [saveAndDismiss]);

  const renderUserVoiceContent = useCallback(() => (
    <div
      className={classes.userVoiceStatement}
    >
      <ReactTemplate
        model={{
          a: text => (
            (openLinkService && openLinkService.openLinkInBrowser) ?
              <Link
                onClick={() => { openLinkService.openLinkInBrowser('https://go.microsoft.com/fwlink/?LinkId=517018'); }}
                onKeyUp={(event) => {
                  if (event.keyCode === keyCode.Enter || event.keyCode === keyCode.Space) {
                    openLinkService.openLinkInBrowser('https://go.microsoft.com/fwlink/?LinkId=517018');
                  }
                }}
                className={classNames(classes.infoText)}
                target="_blank"
                aria-label={text}
                as="a" // Render as <a /> to meet A11Y requirement
                tabIndex="0"
              >
                {`${text} `}
                <Glyphicon glyph="ba-OpenInNewWindow" />
              </Link>
              :
              <Link
                href="https://go.microsoft.com/fwlink/?LinkId=517018"
                className={classNames(classes.infoText)}
                target="_blank"
              >
                {`${text} `}
                <Glyphicon glyph="ba-OpenInNewWindow" />
              </Link>
          ),
        }}
        template={
          i18n.getString(userVoiceString)
        }
      />
    </div>
  ), [classes.infoText, classes.userVoiceStatement, i18n, openLinkService]);

  const renderPrivacyContent = useCallback((text, url) => {
    if (openLinkService && openLinkService.openLinkInBrowser) {
      return (
        <Link
          onClick={() => { openLinkService.openLinkInBrowser(url); }}
          onKeyUp={(event) => {
            if (event.keyCode === keyCode.Enter || event.keyCode === keyCode.Space) {
              openLinkService.openLinkInBrowser(url);
            }
          }}
          className={classNames(classes.infoText, 'feedback-privacy-link')}
          aria-label={text}
          as="a" // Render as <a /> to meet A11Y requirement
          tabIndex="0"
        >
          {`${text} `}
          <Glyphicon glyph="ba-OpenInNewWindow" />
        </Link>
      );
    }
    return (
      <Link
        href={url}
        className={classNames(classes.infoText)}
        target="_blank"
      >
        {`${text} `}
        <Glyphicon glyph="ba-OpenInNewWindow" />
      </Link>
    );
  }, [classes.infoText, openLinkService]);

  const renderTellUsMoreContent = useCallback(() => (
    <React.Fragment>
      <TextField
        className="feedback-message"
        multiline
        rows={4}
        value={feedbackMsg}
        label={i18n.getString(_TL_('Tell us more'))}
        placeholder={i18n.getString(_TL_('Please do not include any confidential or personal information in your feedback.'))}
        onChange={handleFeedbackChange}
        onRenderDescription={onRenderDescription}
      />
      {takeScreenShot ?
        <React.Fragment>
          <Checkbox
            className={classNames(classes.screenshotCheckBox, 'select-screenshot')}
            label={i18n.getString(_TL_('Include a screen capture'))}
            onChange={handleScreenshotSelectionChange}
            checked={selectScreenshot}
          />
          {renderScreenshotContainer()}
        </React.Fragment>
        : null
      }
      <Checkbox
        className={classNames(classes.emailCheckBox, 'select-email')}
        label={i18n.getString(_TL_('Allow Microsoft to contact me'))}
        onChange={handleEmailSelectionChange}
      />
      {
        emailSelection ?
          <TextField
            className="email"
            disabled={!emailSelection}
            ariaLabel={i18n.getString(_TL_('Email address'))}
            value={emailSelection ? emailAddress : ''}
            placeholder={i18n.getString(_TL_('Enter your email'))}
            onChange={handleEmailChange}
          /> : null
      }
      <div
        className={classes.privacyStatement}
      >
        <ReactTemplate
          model={{
            a: text => (
              renderPrivacyContent(text, 'https://petrol.office.microsoft.com/v1/feedback/privacyLink')
            ),
          }}
          template={
            i18n.getString(_TL_('To improve service we\'ll receive some information about your account and device. For more details, please refer to the {{a}}Microsoft privacy statement{{/a}}.'))
          }
        />
      </div>
    </React.Fragment>
  ), [
    classes.emailCheckBox,
    classes.privacyStatement,
    classes.screenshotCheckBox,
    emailAddress,
    emailSelection,
    feedbackMsg,
    handleEmailChange,
    handleEmailSelectionChange,
    handleFeedbackChange,
    handleScreenshotSelectionChange,
    i18n,
    onRenderDescription,
    renderScreenshotContainer,
    selectScreenshot,
    takeScreenShot,
    renderPrivacyContent,
  ]);

  const { fabricPanelStyle } = ComponentSettings;

  return (
    <Panel
      type={PanelType.custom}
      isOpen={isOpen}
      onDismiss={hidePanel}
      onOuterClick={onOuterClick}
      closeButtonAriaLabel={i18n.getString(_TL_('Close'))}
      headerText={i18n.getString(_TL_('Send feedback to Microsoft'))}
      className={classNames(classes.container, 'feedback-panel')}
      onRenderFooterContent={renderFooterContent}
      isFooterAtBottom
      customWidth="370px"
      data-html2canvas-ignore="true"
      styles={{
        commands: {
          ...fabricPanelStyle().commands,
          paddingTop: 18,
        },
        footer: { ...fabricPanelStyle().footer },
        root: { top: '60px' },
        content: { paddingBottom: '80px' },
      }}
    >
      <ChoiceContent
        selectedKey={selectedKey}
        i18n={i18n}
        triggerHelpPanel={triggerHelpPanel}
        classes={classes}
        setSmile={setSmile}
        onKeyDownSmile={onKeyDownSmile}
        setFrown={setFrown}
        onKeyDownFrown={onKeyDownFrown}
        setIdea={setIdea}
        onKeydownIdea={onKeydownIdea}
        openLinkService={openLinkService}
      />
      {selectedKey === feedbackOptions.idea ? renderUserVoiceContent() : renderTellUsMoreContent()}
      <div style={{ display: 'none' }}>
        <a ref={globalHelpRef} className="HelpTopic" href="/#" > </a>
      </div>
      <Dialog
        onDismiss={handleCancel}
        hidden={hideDialog}
        dialogContentProps={{
          type: DialogType.normal,
          closeButtonAriaLabel: i18n.getString(_TL_('Close')),
          subText: i18n.getString(_TL_('Feedback submissions will not get a support response. If you have questions please select cancel and contact support instead.')),
        }}
        modalProps={{
          isBlocking: true,
          dragOptions: undefined,
          className: 'feedback-confirm-dialog',
        }}
      >
        <DialogFooter>
          <PrimaryButton
            className="submit"
            onClick={handleSend}
            text={i18n.getString(_TL_('Submit'))}
          />
          <DefaultButton
            className="cancel"
            onClick={handleCancel}
            text={i18n.getString(_TL_('Cancel'))}
          />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
}

FeedbackUnthemed.propTypes = {
  i18n: PropTypes.shape({
    getString: PropTypes.func,
  }).isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string,
    screenshotCheckBox: PropTypes.string,
    screenshotContainer: PropTypes.string,
    img: PropTypes.string,
    remainingChar: PropTypes.string,
    emailCheckBox: PropTypes.string,
    privacyStatement: PropTypes.string,
    submitButton: PropTypes.string,
    infoText: PropTypes.string,
  }).isRequired,
  instrumentationService: PropTypes.shape({ log: PropTypes.func.isRequired }),
  openLinkService: PropTypes.shape({ openLinkInBrowser: PropTypes.func }),
  isOpen: PropTypes.bool.isRequired,
  dismissPanel: PropTypes.func.isRequired,
  appId: PropTypes.number.isRequired,
  isTestEnv: PropTypes.bool,
  email: PropTypes.string,
  sessionId: PropTypes.string,
  pageId: PropTypes.string,
  takeScreenShot: PropTypes.bool,
  featureArea: PropTypes.string,
  appData: PropTypes.shape({}),
};

FeedbackUnthemed.defaultProps = {
  isTestEnv: true,
  email: '',
  sessionId: '',
  pageId: '',
  takeScreenShot: true,
  instrumentationService: { log: () => { } },
  openLinkService: {},
  featureArea: '',
  appData: {},
};

export const FeedbackPanel =
  withDefaultStyles(FeedbackUnthemed, feedbackStyles);
