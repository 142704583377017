import * as unthemed from '@bingads-webui-react/primitive-unthemed';
import { withStyleOverride } from './with-style-override';
import { withCustomClassName } from './with-custom-class-name';

export {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ButtonGroup,
  ButtonToolbar,
  Panel,
  PanelGroup,
} from '@bingads-webui-react/layout';

export const Alert = withStyleOverride(unthemed.Alert, ['components.alert']);
export const Label = withStyleOverride(unthemed.Label, ['components.label']);
export const Button = withStyleOverride(unthemed.Button, ['components.button']);
export const Radio = withStyleOverride(unthemed.Radio, ['components.radio']);
export const Checkbox = withStyleOverride(unthemed.Checkbox, ['components.checkbox']);
export const Input = withStyleOverride(unthemed.Input, ['components.input']);
export const Select = withStyleOverride(unthemed.Select, ['components.select']);
export const TextArea = withStyleOverride(unthemed.TextArea, ['components.textarea']);
export const Glyphicon = withStyleOverride(unthemed.Glyphicon, ['components.glyphicon']);

export const Dropdown = withStyleOverride(unthemed.Dropdown, ['components.dropdown']);
export const DropdownToggle = withCustomClassName(unthemed.Dropdown.Toggle, 'react-dropdown-toggle', 'toggle');
export const DropdownMenu = withCustomClassName(unthemed.Dropdown.Menu, 'react-dropdown-menu', 'menu');
export const DropdownButton = withStyleOverride(unthemed.DropdownButton, ['components.dropdownbutton']);
Dropdown.Toggle = DropdownToggle;
Dropdown.Menu = DropdownMenu;

export const MenuItem = withStyleOverride(unthemed.MenuItem, ['components.menuitem']);
export const Modal = withStyleOverride(unthemed.Modal, ['components.modal']);
export const Tooltip = withStyleOverride(unthemed.Tooltip, ['components.tooltip']);
export const { OverlayTrigger, Overlay } = unthemed;
export const Popover = withStyleOverride(unthemed.Popover, ['components.popover']);
export const Navbar = withStyleOverride(unthemed.Navbar, ['components.navbar']);
export const Nav = withStyleOverride(unthemed.Nav, ['components.nav']);

export const NavDropdown
  = withCustomClassName(withStyleOverride(unthemed.NavDropdown, ['components.navdropdown']), 'react-dropdown');

export const NavItem = withStyleOverride(unthemed.NavItem, ['components.navitem']);
export const Image = withStyleOverride(unthemed.Image, ['components.image']);
export const Tabs = withStyleOverride(unthemed.Tabs, ['components.tabs']);
export const Tab = withStyleOverride(unthemed.Tab, ['components.tab']);
