import React from 'react';
import { ContextualMenu, initializeIcons } from '@fluentui/react';

initializeIcons();

const ContextualMenuDropdown = props => (
  <ContextualMenu {...props} />
);

export * from '@fluentui/react';
export { ContextualMenuDropdown as ContextualMenu };
