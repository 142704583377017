import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Alert,
  FormControl,
  ControlLabel,
  Button as BsButton,
  Radio,
  Checkbox,
  Glyphicon as BsGlyphicon,
  Dropdown,
  DropdownButton,
  MenuItem,
  Modal,
  Carousel as BsCarousel,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Navbar as BsNavbar,
  Nav,
  NavDropdown as UnstyledNavDropdown,
  NavItem,
  Tabs,
  Tab,
  Image,
} from 'react-bootstrap';

const Button = React.forwardRef((props, ref) => {
  const { btnStyle, className, ...other } = props;
  let bsStyle = btnStyle;
  let btnClasses = className;

  // For CommandBar button style, add btn-command class
  if (btnStyle === 'command') {
    const btnClass = ['btn-command'];

    btnClass.push(btnClasses);
    btnClasses = className ? btnClass.join(' ') : btnClass.join('');
    bsStyle = 'default';
  }

  return (<BsButton {...other} bsStyle={bsStyle} className={btnClasses} ref={ref} />);
});

Button.propTypes = {
  btnStyle: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  btnStyle: undefined,
  className: undefined,
};

const Glyphicon = React.forwardRef((props, ref) => {
  const {
    iconStyle,
    className,
    glyph,
    bsClass,
    ...other
  } = props;
  let iconGlyph = glyph;
  let iconBsClass = bsClass;
  let iconClasses = className;

  // For bingads specific icons (such as ba-chart), trim ba- in glyph prop and add custom bsClass
  if (iconGlyph && iconGlyph.indexOf('ba-') === 0) {
    const bsClasses = ['iconba'];

    bsClasses.push(iconBsClass);
    iconBsClass = bsClasses.join(' ');
    iconGlyph = iconGlyph.substr(3);
  }

  if (iconStyle) {
    const iconClass = ['icon-'];
    iconClass[0] += iconStyle;

    iconClass.push(iconClasses);
    iconClasses = className ? iconClass.join(' ') : iconClass.join('');
  } else {
    iconClasses = className;
  }

  if (iconClasses) {
    iconClasses = iconClasses.trim();
  }

  return (
    <BsGlyphicon
      {...other}
      bsClass={iconBsClass}
      className={iconClasses}
      glyph={iconGlyph}
      ref={ref}
    />
  );
});

Glyphicon.propTypes = {
  bsClass: PropTypes.string,
  className: PropTypes.string,
  glyph: PropTypes.string.isRequired,
  iconStyle: PropTypes.string,
};

Glyphicon.defaultProps = {
  bsClass: undefined,
  className: undefined,
  iconStyle: undefined,
};

const Input = React.forwardRef((props, ref) => {
  const { isStatic, staticDisplay, ...rest } = props;
  return isStatic ?
    <FormControl.Static
      componentClass="span"
      {...rest}
      type={rest.type}
      ref={ref}
    >
      {staticDisplay(props.value)}
    </FormControl.Static> :
    <FormControl
      {...rest}
      type={rest.type}
      ref={ref}
    />;
});

Input.propTypes = {
  isStatic: PropTypes.bool,
  staticDisplay: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
};

Input.defaultProps = {
  isStatic: false,
  staticDisplay: _.identity,
  type: 'text',
  value: undefined,
};

const Navbar = React.forwardRef((props, ref) => {
  const { navStyle, className, ...other } = props;
  let navClasses = className;

  if (navStyle === 'default') {
    const navClass = ['default-toolbar'];
    navClass.push(navClasses);
    navClasses = className ? navClass.join(' ') : navClass.join('');
  }

  if (navStyle === 'primary') {
    const navClass = ['primary-toolbar'];
    navClass.push(navClasses);
    navClasses = className ? navClass.join(' ') : navClass.join('');
  }

  return (
    <BsNavbar {...other} className={navClasses} ref={ref}>
      {props.children}
    </BsNavbar>
  );
});

Navbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  navStyle: PropTypes.string,
};

Navbar.defaultProps = {
  children: null,
  className: undefined,
  navStyle: 'default',
};

Navbar.Brand = BsNavbar.Brand;

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
  }

  handlePause = (e) => {
    e.preventDefault();
    this.carouselRef.current.pause();
  }

  handlePlay = (e) => {
    e.preventDefault();
    this.carouselRef.current.play();
  }

  render() {
    const {
      controls,
      showPrevNextControls,
      InjectedControls, ...other
    } = this.props;
    let carouselControls = '';

    if (!controls) {
      carouselControls = ' hidden';
    }

    return (
      <div className="carousel-wrapper">
        <BsCarousel ref={this.carouselRef} controls={showPrevNextControls} {...other}>
          {this.props.children}
        </BsCarousel>
        <div className={`carousel-controls${carouselControls}`}>
          <button id="playButton" onClick={this.handlePlay}>Play</button>
          <button id="pauseButton" onClick={this.handlePause}>Pause</button>
        </div>
        {
          InjectedControls ?
            <>
              {InjectedControls}
            </>
            : null
        }
      </div>
    );
  }
}

Carousel.propTypes = {
  children: PropTypes.node,
  controls: PropTypes.bool,
  showPrevNextControls: PropTypes.bool,
  InjectedControls: PropTypes.element,
};

Carousel.defaultProps = {
  children: null,
  controls: true,
  showPrevNextControls: true,
  InjectedControls: null,
};

Carousel.Caption = BsCarousel.Caption;
Carousel.Item = BsCarousel.Item;

export {
  Accordion,
  Alert,
  ControlLabel as Label,
  Button,
  Radio,
  Input,
  Checkbox,
  Glyphicon,
  Dropdown,
  DropdownButton,
  MenuItem,
  Modal,
  Carousel,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Navbar,
  Nav,
  UnstyledNavDropdown as NavDropdown,
  NavItem,
  Tabs,
  Tab,
  Image,
};

export const Select = React.forwardRef((props, ref) => (<FormControl {...props} componentClass="select" ref={ref} />));

export const TextArea = React.forwardRef((props, ref) => (<FormControl {...props} componentClass="textarea" ref={ref} />));
