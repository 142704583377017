import React, { useState } from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from '@fluentui/react';
import { withI18nConsumer } from '@bingads-webui-react/i18n-context';

/**
 * Presentational component for client-side rendered footer
 * @param {object} props component props
 * @param {link[]} props.links links in the footer
 * @param {object} props.texts special texts for the footer
 * @param {object} props.classes cless names for sub components
 * @returns {ReactNode} rendered result
 */
export const AppContentFooter = withI18nConsumer(({
  links,
  texts:
  { copyright, help },
  classes,
  i18n,
  feedbackProps,
}) => {
  const [isOpen, setOpen] = useState(false);

  const openFeedbackPanel = () => {
    setOpen(true);
  };

  const closeFeedbackPanel = () => {
    setOpen(false);
  };

  const {
    isTestEnv,
    email,
    pageId,
    sessionId,
    feedbackLogger,
    appId,
    appData,
  } = feedbackProps;

  const defaultTestAppId = 50025;
  const defaultAppId = 2482;

  const FeedbackPanelComponent = React.lazy(() => import(/* webpackMode: 'eager' */ '@bingads-webui-react/feedback-panel').then(({ FeedbackPanel }) => ({
    default: () => (
      <FeedbackPanel
        appId={appId || (isTestEnv ? defaultTestAppId : defaultAppId)}
        isTestEnv={isTestEnv}
        isOpen={isOpen}
        dismissPanel={closeFeedbackPanel}
        i18n={i18n}
        instrumentationService={feedbackLogger}
        email={email}
        pageId={pageId}
        sessionId={sessionId}
        appData={appData}
      />),
  })));

  const renderAppContentFooter = () => (
    <div className={classNames(classes.container, 'ba-content-footer')}>
      <ul className={classNames(classes.footerList, 'footer-link-list')}>
        <li className={classNames(classes.footerItem, 'footer-copyright-text')}>
          {copyright}
        </li>
        {_.map(links, link =>
          (
            <li
              key={link.text}
              className={classNames(classes.footerItem, 'footer-link-item', link.text)}
            >
              <Link
                href={link.url}
                target="_blank"
                onClick={link.hasFeedbackPanel && openFeedbackPanel}
              >
                {link.text}
              </Link>
            </li>
          ))}
        <li className={classNames(classes.footerItem, 'footer-link-item')}>
          <a
            href="/#"
            className={classNames('HelpTopic', 'analysable')}
            analytics-id="helpFromFooter:{{RailViewID}}"
            role="button"
          >
            {help}
          </a>
        </li>
      </ul>
      <React.Suspense fallback={null}>
        {isOpen && <FeedbackPanelComponent />}
      </React.Suspense>
    </div>
  );

  return (
    renderAppContentFooter()
  );
});

AppContentFooter.propTypes = {
  texts: PropTypes.shape({
    copyright: PropTypes.node.isRequired,
    help: PropTypes.node.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.node,
  })).isRequired,
  feedbackProps: PropTypes.shape({
    feedbackLogger: PropTypes.shape({
      log: PropTypes.func,
    }),
    isTestEnv: PropTypes.bool,
    email: PropTypes.string,
    pageId: PropTypes.string,
    sessionId: PropTypes.string,
  }),
};

AppContentFooter.defaultProps = {
  feedbackProps: {
    feedbackLogger: { log: () => { } },
    isTestEnv: true,
    email: '',
    pageId: '',
    sessionId: '',
  },
};
