import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Checkbox } from './controls';

export class CheckboxGroup extends React.PureComponent {
  // for now eslint-plugin-react doesn't recognize `getDerivedStateFromProps`
  // which is introduced in React 16.3
  static propTypes = {
    checked: PropTypes.arrayOf(PropTypes.any), // eslint-disable-line
    getItemId: PropTypes.func,
    getItemValue: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    name: PropTypes.string, // eslint-disable-line
    onChange: PropTypes.func.isRequired,
    renderItem: PropTypes.func,
    className: PropTypes.string,
  }

  static defaultProps = {
    checked: [],
    getItemId: (item, index) => String(index),
    getItemValue: null,
    name: null,
    renderItem: () => null,
    className: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      name: nextProps.name || prevState.name || _.uniqueId('checkbox-group-'),
    };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const getItemValue = this.props.getItemValue || this.props.getItemId;

    return (
      <React.Fragment>
        {this.props.items.map((item, index) => {
          const id = this.props.getItemId(item, index);
          const onChange = (event) => {
            const checked = event.target.checked ?
              [...this.props.checked, item] :
              _.without(this.props.checked, item);

            this.props.onChange(checked);
          };

          return (
            <Checkbox
              key={id}
              id={`${this.state.name}-${id}`}
              className={this.props.className}
              checked={_.contains(this.props.checked, item)}
              name={this.state.name}
              onChange={onChange}
              value={getItemValue(item, index)}
            >
              {this.props.renderItem(item, index)}
            </Checkbox>
          );
        })}
      </React.Fragment>
    );
  }
}
