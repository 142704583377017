import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { jss, ThemeProvider, withTheme } from 'react-jss';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { get } from '@bingads-webui-universal/primitive-utilities';

const emptyTheme = {};

const styleSheet = jss.createStyleSheet({}).attach();

const getClassName = (theme, fieldName) => {
  const ruleName = fieldName.split('.').pop();
  const elementStyles = get(theme, fieldName);

  let rule = styleSheet.getRule(ruleName);

  if (_.isEmpty(rule) && !_.isEmpty(elementStyles)) {
    rule = styleSheet.addRule(ruleName, elementStyles);
  }

  return get(rule, `options.classes.${ruleName}`);
};

export function withStyleOverride(Component, fieldNames = []) {
  const StyledComponent = ({
    theme, className, forwardRef, ...props
  }) => {
    const themeClasses = _.map(fieldNames, _.partial(getClassName, theme));
    const nameProp = classNames(themeClasses, className);

    return (<Component
      className={_.isEmpty(nameProp) ? null : nameProp}
      {...props}
      ref={forwardRef}
    />);
  };

  StyledComponent.propTypes = {
    className: PropTypes.string,
    theme: PropTypes.objectOf(PropTypes.object),
    forwardRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.any }),
    ]),
  };

  StyledComponent.defaultProps = {
    className: '',
    theme: {},
    forwardRef: null,
  };

  const WrappedComponent = withTheme(StyledComponent);

  const ThemedComponent = React.forwardRef((props, ref) => (
    <ThemeProvider theme={emptyTheme}>
      <WrappedComponent {...props} forwardRef={ref} />
    </ThemeProvider>
  ));

  hoistNonReactStatics(ThemedComponent, Component);

  return ThemedComponent;
}
